import React from 'react';

import Error from '../../components/Error';

class NotFoundContainer extends React.Component {
  render() {
    return (<Error errorCode="404" />);
  }
}

export default NotFoundContainer;
